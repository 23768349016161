/* eslint-disable jsx-a11y/anchor-is-valid */
import { Profile } from '../components/Profile';
import Button from 'react-bootstrap/Button';

interface HomePageProps {
  onLoginChanged: () => void;
}

export function HomePage({onLoginChanged}: HomePageProps) {
  return (
    <div className="flex flex-column full">
      <Profile />
      <Button 
        variant="outline-dark" 
        onClick={() => onLoginChanged()}
      >
        Logout
      </Button>
    </div>
  )
}
