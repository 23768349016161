import Button from 'react-bootstrap/Button';
import { CurrencyEth } from 'phosphor-react';

interface LoginPageProps {
  onLoginChanged: () => void;
}

export function LoginPage({ onLoginChanged }: LoginPageProps) {

  return (
    <>
      <div className="full">
        <div className="align-center flex flex-column full just-around">
          <CurrencyEth size={100} color="#6942b6"/>
          <Button 
            variant="dark" 
            onClick={() => onLoginChanged()}
          >
            Login With Google
          </Button>
        </div>
      </div>
    </>
  );
}