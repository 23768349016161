import { LoginPage } from './pages/LoginPage';
import { HomePage } from './pages/HomePage';
import { useAuth0 } from '@auth0/auth0-react';

export function App() {
  const { isLoading,  error, loginWithRedirect, logout,  isAuthenticated  } = useAuth0();

  return (
    <div className="flex align-center just-center full">
      <div className="box flex align-center just-center">
      {error && <p>Authentication Error</p>}
      {!error && isLoading && <p>Loading...</p>}
      {!error && !isLoading && (
        <>
          {
            !isAuthenticated 
            ? <LoginPage onLoginChanged={loginWithRedirect} />
            : <HomePage onLoginChanged={logout}/>
          }
        </>
      )}
      </div>
    </div>
  );
}