import { useAuth0 } from '@auth0/auth0-react';

export function Profile () {
  const { user,  isAuthenticated } = useAuth0<object | any>();
  const { email, name, picture } = user;
  return (
    isAuthenticated ? (
      <div className="flex flex-column full">
        <p className="message">Hello World!</p>
        <div className="align-center flex">
          {picture && <img className="img-profile" src={picture} alt={name} />}
          <div className="user-info">
            <span >{name}</span>
            <span className="info-email">{email}</span>
          </div>
        </div>
      </div>
    ) : null
  )
}